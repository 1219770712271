<template>
  <div>
    <x-header>
      <img slot="headerImage" src="../../assets/haiwaicang/banner.jpg" alt="" />
      <!-- <h1 slot="headerTitle">海外仓—100000㎡欧洲甲级仓库资质标准</h1> -->
      <!-- <h1 slot="headerTitle">
        海外仓＋境外产品展销中心—100000㎡欧洲甲级仓库资质标准
      </h1> -->
        <h1 slot="headerTitle">
        {{$store.state.navShow == true? ' 海外仓＋境外产品展销中心—100000㎡欧洲甲级仓库资质标准' : 'Overseas warehouse—100,000㎡ European Class A warehouse qualification standard'}}
      </h1>
    </x-header>
    <div class="sea">
      <!-- <p style="margin-bottom:0;">
        MG集团在俄罗斯莫斯科建有100000㎡的海外仓，具有欧洲甲级仓库资质标准，拥有先进的仓储分拨信息管理系统、现代化的仓储设备和专业高效的本土化运营团队，俄方员工超过95%，可为外贸企业提供专业的跨境贸易服务。2019年，被河北省商务厅认定为“河北公共海外仓”，成为俄罗斯首家河北海外仓，并在海外仓内建设境外河北产品展示中心。
      </p> -->
       <p style="margin-bottom:0;">
        {{$store.state.navShow == true? 'MG集团在俄罗斯莫斯科建有100000㎡的海外仓，具有欧洲甲级仓库资质标准，拥有先进的仓储分拨信息管理系统、现代化的仓储设备和专业高效的本土化运营团队，俄方员工超过95%，可为外贸企业提供专业的跨境贸易服务。2019年，被河北省商务厅认定为“河北公共海外仓”，成为俄罗斯首家河北海外仓，并在海外仓内建设境外河北产品展示中心。' : 'MG Group has an overseas warehouse of 100,000 square meters in Moscow, Russia, with European Grade A warehouse qualification standard, advanced storage allocation information management system, modern storage equipment and professional and efficient localization operation team, and more than 95% of Russian employees, which can provide professional cross-border trade services for foreign trade enterprises. In 2019, it was identified as "Hebei Public overseas warehouse" by Hebei Department of Commerce, becoming the first Hebei overseas warehouse in Russia, and has built overseas Hebei products exhibition center in the overseas warehouse.'}}
      </p>
      <p>{{$store.state.navShow == true?'海外仓及其配套的海外展示中心可实现直接与厂商代表面对面交流的购物体验，优化购物体验的同时为中国中小企业产品进入当地市场提供报关清关、仓储物流、市场推广、渠道拓展等综合服务，未来将以电商平台海外仓为发展方向，提升中国产品在当地的占有率和知名度。' : 'Overseas warehouse and its supporting overseas exhibition center can realize direct face-to-face communication with manufacturer representative, optimize the shopping experience, provide comprehensive services such as customs declaration and clearance, warehousing and logistics, market promotion, channel expansion for the products of Chinese small and medium-sized enterprises to enter the local market. In the future, it will take the overseas warehouse of e-commerce platform as the development direction to improve the local share and popularity of Chinese products.'}}</p>
      <!-- 海外仓优势 -->
      <div class="advantage">
        <p>{{$store.state.navShow == true?'海外仓优势' : 'Overseas warehouse advantage'}}</p>
        <div class="advantage_intro">
          <div class="intro_L">
            <div class="intro_items">
              <span>
                <countTo
                  :startVal="0"
                  :endVal="100000"
                  :duration="3000"
                  separator=""
                ></countTo>
              </span>
              <p>{{$store.state.navShow == true?'占地面积平方米':'Covers an area of square meters'}}</p>
            </div>
            <div class="intro_items">
              <span>
                <countTo :startVal="0" :endVal="95" :duration="3000"></countTo>
              </span>
              <time>%</time>
              <p>{{$store.state.navShow == true?'俄方员工占比': 'Russian employees account for '}}</p>
            </div>
            <div class="intro_items">
              <span>
                <countTo :startVal="0" :endVal="15" :duration="3000"></countTo>
              </span>
              <time>km</time>
              <p>{{$store.state.navShow == true?'距离市区': 'from the urban area'}}</p>
            </div>
            <div class="intro_items">
              <span>
                <countTo :startVal="0" :endVal="30" :duration="3000"></countTo>
              </span>
              <time>km</time>
              <p>{{$store.state.navShow == true?'距离两个机场' : 'from the two airports'}}</p>
            </div>
          </div>
          <div class="intro_r">
            <img src="../../assets/haiwaicang/youshi.png" alt="" />
          </div>
        </div>
      </div>

      <div class="youxiu_listsShow">
        <div
          class="lists_items"
          v-for="(item, index) in $store.state.navShow == true? youxiuListsArr : youxiuListsArrE "
          :key="index"
          data-aos="flip-left"
        >
          <img :src="item.youxiuImg" alt="" />
          <p>{{ item.youxiuName }}</p>
          <p>{{ item.youxiuEffect }}</p>
        </div>
      </div>

      <div></div>
    </div>
    <!-- 海外仓图片 -->
    <div class="seaShowImgDiv">
      <div class="showImg">
        <div class="showImg_L" data-aos="fade-right">
          <img v-if="$store.state.navShow == true" src="../../assets/haiwaicang/haiwaicang1.jpg" alt="" />
          <img v-else src="../../assets/haiwaicang/haiwaicang1E.jpg" alt="" />
        </div>
        <div class="showImg_R">
          <img
            v-if="$store.state.navShow == true"
            src="../../assets/haiwaicang/haiwaicang3.jpg"
            alt=""
            data-aos="fade-down-left"
          />
            <img v-else src="../../assets/haiwaicang/haiwaicang3E.jpg" alt="" />
          <img
            src="../../assets/haiwaicang/haiwaicang2.jpg"
            alt=""
            data-aos="fade-up-left"
          />
        </div>
      </div>
      <div class="showDivImg">
        <img class="bottom1" src="../../assets/haiwaicang/bottom1.png" alt="" />
        <img
          class="chache"
          src="../../assets/haiwaicang/chache.png"
          alt=""
          data-aos="fade-left"
        />
        <img
          class="bottomDian"
          src="../../assets/pages_bottom_img.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>
<script>
// 头尾导入
import xHeader from '../../components/header.vue'
import countTo from 'vue-count-to'
export default {
  components: {
    // 使用/注册引入的组件 components
    xHeader,
    countTo
  },
  data () {
    return {
      // 优秀列表数组
      youxiuListsArr: [
        {
          youxiuImg: require('../../assets/haiwaicang/item1.png'),
          youxiuName: '大客户个性化',
          youxiuEffect: '定制服务'
        },
        {
          youxiuImg: require('../../assets/haiwaicang/item2.png'),
          youxiuName: '优秀的客服',
          youxiuEffect: '管理团队'
        },
        {
          youxiuImg: require('../../assets/haiwaicang/item3.png'),
          youxiuName: '专业的仓储',
          youxiuEffect: '信息化系统'
        },
        {
          youxiuImg: require('../../assets/haiwaicang/item4.png'),
          youxiuName: '俄罗斯全境',
          youxiuEffect: '派送不分区'
        },
        {
          youxiuImg: require('../../assets/haiwaicang/item5.png'),
          youxiuName: '专属的',
          youxiuEffect: '车队派送'
        }
      ],
      youxiuListsArrE: [
        {
          youxiuImg: require('../../assets/haiwaicang/item1.png'),
          youxiuName: ' For major customers',
          youxiuEffect: 'Customized service'
        },
        {
          youxiuImg: require('../../assets/haiwaicang/item2.png'),
          youxiuName: 'Excellent customer service',
          youxiuEffect: 'Management team'
        },
        {
          youxiuImg: require('../../assets/haiwaicang/item3.png'),
          youxiuName: 'Professional warehousing',
          youxiuEffect: 'Information system'
        },
        {
          youxiuImg: require('../../assets/haiwaicang/item4.png'),
          youxiuName: 'The whole territory of Russia is delivered',
          youxiuEffect: 'Without division'
        },
        {
          youxiuImg: require('../../assets/haiwaicang/item5.png'),
          youxiuName: 'Exclusive',
          youxiuEffect: 'Fleet delivery'
        }
      ]
    }
  }
}
</script>
<style lang="less" scoped>
.sea {
  width: var(--width1200px);
  // background: orange;
  margin: 108px auto 0;
  text-align: left;
  & > p {
    font-family: Microsoft YaHei;
    color: #666666;
    text-indent: 32px;
    line-height: 35px;
    margin-bottom: 85px;
  }
  // 优势
  .advantage {
    & > p {
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #2e67b1;
    }
    & .advantage_intro {
      width: 100%;
      height: 135px;
      background: #2e67b1;
      margin: 30px 0 55px 0;
      display: flex;
      position: relative;
      .intro_L {
        width: 60%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        // background: red;

        color: #ffffff;

        & .intro_items {
          // text-align: center;
          & > span {
            font-size: 50px;
            // font-family: AkzidenzGroteskBQ;
            font-weight: bold;
            & > span {
              font-size: 50px;
            }
          }
          & > p {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            margin-left: 4px;
          }
          & > time {
            font-size: 25px;
            margin-left: -7px;
            font-weight: bold;
          }
        }
      }
      .intro_r {
        width: 40%;
        height: 135px;
        // background: orange;
        img {
          position: absolute;
          right: 0;
          bottom: 0px;
        }
      }
    }
  }
  // 优秀列表展示
  .youxiu_listsShow {
    width: 1200px;
    height: 260px;
    // background:red;
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
    .lists_items {
      width: 216px;
      height: 100%;
      background: #ffffff;
      box-shadow: 0px 0px 20px 0px rgba(41, 92, 169, 0.1);
      text-align: center;

      & > p {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #666666;
        line-height: 27px;
      }
      & > img {
        margin: 46px 0 46px 0;
      }
    }
  }
}
// 最后展示的海外仓图片
.seaShowImgDiv {
  width: 100%;
  height: 815px;
  // background: orange;
  position: relative;
  margin-top: 78px;
  .showImg {
    width: var(--width1200px);
    margin: 0 auto;
    height: 610px;
    // background: red;
    display: flex;
    justify-content: space-between;
    .showImg_L {
    }
    .showImg_R {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .showDivImg {
    height: 400px;
    .bottom1 {
      position: absolute;
      bottom: 6.5vw;
      left: 0;
    }
    .chache {
      position: absolute;
      bottom: 7.6vw;
      right: 8.6vw;
    }
    .bottomDian {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}
</style>
