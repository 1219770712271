import { render, staticRenderFns } from "./overseas_house.vue?vue&type=template&id=de1cbebe&scoped=true&"
import script from "./overseas_house.vue?vue&type=script&lang=js&"
export * from "./overseas_house.vue?vue&type=script&lang=js&"
import style0 from "./overseas_house.vue?vue&type=style&index=0&id=de1cbebe&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de1cbebe",
  null
  
)

export default component.exports